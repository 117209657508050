#header {
    h1 {
        font-size: 2.5rem;
    }
    a {
        color: white;
        text-decoration: none;
        border-bottom: 1px solid;

        &.active {
            text-decoration: none;
            border-bottom: 3px solid;
            padding-bottom: 0px;
        }
        &.brand {
            border-bottom: none;
            text-decoration: none !important;
            font-weight: normal !important;
        }
    }
    .wide {
        letter-spacing: 0.25rem;
    }
    .hdr-img {
        height: 2.5rem;
        display: inline-block;
    }
}