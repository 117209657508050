.page-main {
    h2 {
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }
    p, ul {
        padding-bottom: 1.5rem;
    }
    .ecw {
        color: #EB7F56;
        font-weight: bold;
    }
}