.page-letters {
    h2 {
        font-size: 1.5rem;
        font-weight: bold;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
    }
    ul.state li {
        &:hover {
            background: #222;
        }
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
    .city {
        display: inline-block;
        width: 16rem;
    }
    .link {
        display: inline-block;
        width: 8rem;
    }
    .location {
        input {
            background: #333;
        }
    }
}